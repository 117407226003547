import React from 'react';
import NoChrome from './NoChrome';
import Prank from './Prank';

import './App.css';

const isChrome = navigator.userAgent.indexOf('Chrome') > -1 || navigator.userAgent.indexOf('CriOS') > -1;

function App() {
  return <div className="App">{isChrome ? <Prank /> : <NoChrome />}</div>;
}

export default App;
