import React from 'react';
import { ReactComponent as Image } from './icons/mindfulness.svg';

import './NoChrome.css';

export default function NoChrome() {
  return (
    <div className="NoChrome">
      <Image className="Image" />
      <div className="Subtitle">
        Only Chrome is supported for now&nbsp;
        <span role="img" aria-label="relax">
          😌
        </span>
      </div>
    </div>
  );
}
