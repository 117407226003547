import React, { useState } from 'react';
import { ReactComponent as Image } from './icons/checking.svg';

import './Chrome.css';

export default function NoChrome() {
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState('');

  const interval = setInterval(() => {
    if (progress.length >= 5) {
      clearInterval(interval);
      setIsLoading(false);
    } else {
      setProgress(progress + '.');
    }
  }, 1000);
  if (isLoading) {
    return (
      <div className="Chrome">
        <Image className="Image" />
        <div className="Subtitle">Checking{progress}</div>
      </div>
    );
  }
  window.location.assign('https://www.youtube.com/watch?v=dQw4w9WgXcQ?autoplay=1');
}
